export const copyToClipboard = (content: string) => {
  if ('ClipboardItem' in window) {
    navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([content], {
          type: 'text/plain',
        }),
      }),
    ]);
  } else {
    // workaround for firefox
    const tempElement = document.createElement('div');
    tempElement.setAttribute('style', 'position: fixed; top: 0; left: 0; color: transparent;');
    tempElement.innerHTML = content.replace(/\n/g, '<br/>');

    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);

    const selection = window.getSelection();
    selection!.removeAllRanges();
    selection!.addRange(range);
    // @ts-ignore
    window.customCopyInProgress = true;
    document.execCommand('copy');
    // @ts-ignore
    window.customCopyInProgress = false;
    selection!.removeAllRanges();
    document.body.removeChild(tempElement);
  }
};
