import { ButtonSubmit } from '@/components/common/form/ButtonSubmit';
import { Checkbox, Form, Input } from 'antd';
import { useSmartForm } from '@/hooks/form';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';
import React, { useState } from 'react';
import { checkboxGroupRequiredValidator, requiredValidator, stringLengthValidator } from '@/helpers/validators';
import { Button } from '@/components/common/button/Button';
import { useUpdateProfilePreferencesMutation, useViewCurrentUserProfileQuery } from '@/api/profile';
import s from './AISurveyDialog.module.less';
import { AI_SURVEY_DISCOUNT } from '@/config/constants';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { msg } from '@/helpers/msg';
import { Link } from 'react-router-dom';

type FormPayload = {
  options: string[];
  custom?: string;
};

type AISurveyFormProps = {
  onCancel: () => void;
  onComplete: () => void;
};

export const AISurveyForm = ({ onCancel, onComplete }: AISurveyFormProps) => {
  const { smartForm, values } = useSmartForm<FormPayload>({}, ['options']);

  const [submissionFailed, setSubmissionFailed] = useState(false);

  const { data: profile, refetch } = useViewCurrentUserProfileQuery();
  const [updatePreferences, { isLoading }] = useUpdateProfilePreferencesMutation();

  const surveyCompleted = profile?.preferences?.aiCheck?.isCompleted;

  const handleSubmit = async ({ custom, options }: FormPayload) => {
    let submittedOptions = options;

    if (options.includes('Other')) {
      submittedOptions = [...options.filter((option) => option !== 'Other'), custom || ''];
    }

    try {
      await updatePreferences({
        preferences: {
          ...(profile?.preferences || {}),
          aiCheck: {
            isCompleted: true,
            options: submittedOptions,
            isOrderPaid: false,
          },
        },
      }).unwrap();

      refetch();
      onComplete();
    } catch (err) {}
  };

  const { options } = values;

  const { isSm } = useBreakpoints();

  const customOptionSelected = options?.includes('Other');

  const handleCopyClick = () => {
    copyToClipboard(AI_SURVEY_DISCOUNT);
    msg.success('Discount code copied to clipboard!');
  };

  return surveyCompleted ? (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <h2 className={s.discountHeading}>Your discount code:</h2>
      <div className={s.code} onClick={handleCopyClick}>
        <span>{AI_SURVEY_DISCOUNT}</span>
      </div>
      <Link to={`order?discountCode=${AI_SURVEY_DISCOUNT}`} onClick={onCancel}>
        <Button modification="primary" size="large">
          Use now!
        </Button>
      </Link>
    </div>
  ) : (
    <Form
      onFinish={handleSubmit}
      {...smartForm}
      validateTrigger="submit"
      onFinishFailed={() => {
        setSubmissionFailed(true);
      }}
      onChange={async () => {
        if (!submissionFailed) return;
        try {
          await smartForm.form.validateFields();
          setSubmissionFailed(false);
        } catch (error) {}
      }}
    >
      <p>
        We’d love to learn more about the AI detectors you or your academic institution use, in order to improve our automatic AI detection
        service. Please select one or more options below:
      </p>

      <Form.Item name="options" rules={[checkboxGroupRequiredValidator]}>
        <Checkbox.Group
          options={[
            { label: 'Turnitin', value: 'Turnitin' },
            { label: 'Copyleaks', value: 'Copyleaks' },
            { label: 'Originality.AI', value: 'Originality.AI' },
            { label: 'GPTZero', value: 'GPTZero' },
            { label: 'Scribbr', value: 'Scribbr' },
            { label: 'Winston AI', value: 'Winston AI' },
            { label: 'Other', value: 'Other' },
          ]}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${isSm ? 2 : 3}, 1fr)`,
            gridGap: 10,
          }}
        />
      </Form.Item>

      {customOptionSelected && (
        <Form.Item name="custom" rules={customOptionSelected ? [requiredValidator, stringLengthValidator(3, 120)] : undefined}>
          <Input autoFocus maxLength={120} placeholder="Type in your option" />
        </Form.Item>
      )}
      <div style={{ display: 'flex', gap: 16, flexDirection: isSm ? 'column' : 'row' }}>
        <Button onClick={onCancel} size={isSm ? 'large' : 'middle'}>
          Later
        </Button>
        <ButtonSubmit loading={isLoading} style={{ width: isSm ? '100%' : 'auto' }} size={isSm ? 'large' : 'middle'} disabled={isLoading} />
      </div>
    </Form>
  );
};
