import { Form, FormInstance, FormProps } from 'antd';
import { useCallback, useState } from 'react';

export type SmartForm<T> = Pick<FormProps, 'initialValues' | 'onValuesChange' | 'layout' | 'className' | 'scrollToFirstError'> & {
  form: FormInstance<T>; // override coz `form?: ...`, but form instance always will be returned
  onKeyDown: (e: React.KeyboardEvent<HTMLFormElement>) => void;
};
type UseSmartFormReturnType<T> = {
  smartForm: SmartForm<T>; // spread this
  values: T;
  setValues: (changedValues: RecursivePartial<T>) => void;
  form: FormInstance<T>;
};

/*
  Hook for smart form validation.

  How to use?

  const { values, setValues, smartForm } = useSmartForm<Type>(form);

  Dependency watcher (example from PriceQuoteCreateForm):
      useEffect(() => {
        if (!site) return;
        triggerChange({ idSite: site.id });
      }, [site.id]);

  Not need all deps? -> // eslint-disable-line

  // Sync form values with values (state)
  <Form name="custom" {...smartForm}>
    ...
  </Form>

*/
/**
 *
 * @param initialValues
 * @param watchList - all dependencies (if changed - trigger re-render)
 */
function useSmartForm<T>(initialValues?: Partial<T>, watchList?: (keyof T)[]): UseSmartFormReturnType<T> {
  const [form] = Form.useForm<T>();

  // Coz: Warning: Instance created by `useForm` is not connected to any Form element. Forget to pass `form` prop? (AntDesign)
  const [values, setValues] = useState<T>((initialValues || {}) as T); // Empty object as default state instead form.getFieldsValue()

  // Proxy all changes
  const triggerChange = useCallback(
    (changedValues: RecursivePartial<T>): void => {
      const values = form.getFieldsValue(); // important! coz: batching!
      const newValues = { ...values, ...changedValues };
      form.setFieldsValue(newValues);
      setValues(newValues);
    },
    [form],
  );

  // Submit on Cmd+Enter
  const handleKeyDown = (e) => {
    if (!(window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) return; // check Command (Mac) or Ctrl (win)
    if (e.keyCode === 13) form.submit(); // enter
    e.stopPropagation();
  };

  const onValuesChange = useCallback(
    (_, values) => {
      const changedKeys = Object.keys(_) as (keyof T)[];
      const shouldUpdate = !watchList || watchList.some((key) => changedKeys.includes(key)); // <- optimize re-renders
      shouldUpdate && setValues(values); // <- test & debug
    },
    [watchList],
  );

  return {
    values,
    setValues: triggerChange, // use this function for sync form values & re-render component
    form,

    // Default form props
    smartForm: {
      onValuesChange,
      form,
      initialValues, // set: name & initialValues
      layout: 'vertical',
      className: 'default-form',
      onKeyDown: handleKeyDown,
    }, // simple use
  };
}

export { useSmartForm };
