import { useAuth } from '@/store/auth/authHooks';
import { useEffect } from 'react';
import { useUpdateProfileTimezoneMutation, useViewCurrentUserProfileQuery } from '@/api/profile';

export const useTimezoneAutoset = () => {
  const { isLoggedIn } = useAuth();
  const { data } = useViewCurrentUserProfileQuery(undefined, { skip: !isLoggedIn });

  const [updateTimezone] = useUpdateProfileTimezoneMutation();

  useEffect(() => {
    if (!isLoggedIn || !data) return;
    const timezone = data.timezone;

    if (timezone) return;

    const browserTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    if (!browserTimezone) return;

    const finalTimezone =
      {
        'Europe/Kyiv': 'Europe/Kiev',
      }[browserTimezone] || browserTimezone;

    updateTimezone({ timezone: finalTimezone });
  }, [isLoggedIn, data, updateTimezone]);
};
