import { useEffect, useState } from 'react';

import styles from './Confetti.module.less';
import cn from 'classnames';

type ConfettiProps = {
  initiated?: boolean;
  message?: string;
  onComplete?: () => void;
};

export const Confetti = ({ initiated, message, onComplete }: ConfettiProps) => {
  const items = new Array(150).fill(0, 0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!initiated) return;
    setVisible(true);
    const timeoutId = setTimeout(() => {
      setVisible(false);
      onComplete?.();
    }, 2500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [initiated, onComplete]);

  return initiated && visible ? (
    <div className={styles.wrapper}>
      {items.map((_, idx) => (
        <div key={idx} className={cn(styles.confetti, styles[`confetti-${idx}`])}></div>
      ))}
      {message && <div className={styles.emoji}>{message}</div>}
    </div>
  ) : null;
};
