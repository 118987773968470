import { Button, ButtonProps } from '@/components/common/button/Button';
import { Form } from 'antd';
import { FC } from 'react';

interface Props extends ButtonProps {
  loading?: boolean; // only one required prop!
}

export const ButtonSubmit: FC<Props> = ({ children, hidden, ...props }) => (
  <Form.Item style={{ margin: 0 }} hidden={hidden}>
    <Button modification="primary" htmlType="submit" {...props}>
      {children || 'Submit'}
    </Button>
  </Form.Item>
);
