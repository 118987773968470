import { IOrderCreatePayload } from '@/types/order';
import { IPriceQuote } from '@/types/priceQuote';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExtraFeatureTypes } from '@/types/extraFeature';
import { RootState } from '@/store/store';
import { uniq } from 'lodash';

export type DiscountError = {
  type: 'expired' | 'exhausted' | 'private' | 'unknown';
  message: string;
  details?: object;
};

export type IOrderState = {
  orderPayload: Partial<
    IOrderCreatePayload & {
      preferredWriter?: boolean;
      selectedFeatures?: number[];
    }
  >;
  priceQuote: Partial<IPriceQuote>;
  discountError: DiscountError | null;
};

export const orderInitialState: IOrderState = {
  orderPayload: {
    title: '',
    details: '',
    sources: 1,
    idExtraFeatures: [],
    units: 1,
    customAcademicDiscipline: 'Not listed',
    customCitationStyle: 'Not listed',
    idPreferredWriters: [],
    isOkWithNonPreferred: true,
  },
  priceQuote: {
    dynamicBodyPrice: undefined,
    dynamicTotalPrice: undefined,
    associationExtraFeatures: [],
  },
  discountError: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: {
    setOrderPayload: (state: IOrderState, { payload }: PayloadAction<Partial<IOrderCreatePayload>>) => {
      state.orderPayload = payload;
    },
    setOrderDiscountCode: (state: IOrderState, { payload }: PayloadAction<string>) => {
      state.orderPayload = { ...state.orderPayload, discountCode: payload };
    },
    setPriceQuote: (state: Partial<IOrderState>, { payload }: PayloadAction<Partial<IPriceQuote>>) => {
      state.priceQuote = payload;
    },
    setIdPreferredWriters: (state: Partial<IOrderState>, { payload }: PayloadAction<number[]>) => {
      state.orderPayload = {
        ...state.orderPayload,
        idPreferredWriters: payload,
        selectedFeatures: state.orderPayload?.selectedFeatures
          ? [...state.orderPayload.selectedFeatures, ExtraFeatureTypes.PreferredWriter]
          : [ExtraFeatureTypes.PreferredWriter],
      };
    },
    setDiscountError: (state: Partial<IOrderState>, { payload }: PayloadAction<DiscountError | null>) => {
      state.discountError = payload;
    },
    updateOrderPayload: (state: IOrderState, { payload }: PayloadAction<Partial<IOrderCreatePayload>>) => {
      state.orderPayload = {
        ...state.orderPayload,
        ...payload,
        selectedFeatures: uniq([...(state.orderPayload.selectedFeatures || []), ...(payload.selectedFeatures || [])]),
      };
    },
    restoreOrderState: () => orderInitialState,
  },
});

export default orderSlice.reducer;

export const {
  setOrderPayload,
  setPriceQuote,
  restoreOrderState,
  setOrderDiscountCode,
  setIdPreferredWriters,
  setDiscountError,
  updateOrderPayload,
} = orderSlice.actions;

export const discountErrorSelector = ({ order }: RootState) => order.discountError;

export const discountOrderFormSelector = ({ order }: RootState) => order.orderPayload?.discountCode;
