import * as EmailValidator from 'email-validator';
import { Rule } from 'rc-field-form/lib/interface';

export const validateRequired = async (_: unknown, value: unknown) => {
  if (value === 0) return true;

  if (!value) {
    return Promise.reject('Field is required');
  }

  if (typeof value === 'string' && !value.trim()) {
    return Promise.reject('Field is required');
  }

  if (Array.isArray(value) && !value.length) {
    return Promise.reject('Field is required');
  }

  return true;
};

export const validateEmail = async (_: unknown, value: string) => {
  const trimmedValue = value?.trim();
  if (!trimmedValue) return true;
  if (EmailValidator.validate(trimmedValue)) {
    return true;
  }

  return Promise.reject('Enter valid email');
};

export const validateName = async (_: unknown, value: string) => {
  const val = value?.trim();
  if (!val) {
    return Promise.reject('Field should be at least 2 characters long.');
  }

  const regex = /^([A-Za-z\s]*)$/gi;

  // eslint-disable-next-line no-control-regex
  if (!regex.test(value)) {
    return Promise.reject('Field should contain only Latin characters.');
  }

  if (val.length < 2) {
    return Promise.reject('Field should be at least 2 characters long.');
  }

  if (val.length > 45) {
    return Promise.reject('Field should be no longer than 45 characters.');
  }

  return true;
};

export const validateExternalCredentials = async (_: unknown, value: string) => {
  const val = value?.trim();
  if (!val) {
    return Promise.reject('Field is required');
  }

  if (val.length < 2) {
    return Promise.reject('Field should be at least 2 characters long.');
  }

  if (val.length > 255) {
    return Promise.reject('Field should be no longer than 255 characters.');
  }

  return true;
};

export const validateNameWithNumbers = async (_: unknown, value: string) => {
  const val = value?.trim();
  if (!val) {
    return true;
  }
  // eslint-disable-next-line no-control-regex
  const regex = /^[\x00-\x7F]{2,45}$/gi;

  if (val.length < 2) {
    return Promise.reject('Field should be at least 2 characters long.');
  }

  if (val.length > 45) {
    return Promise.reject('Field should be no longer than 45 characters.');
  }

  if (!regex.test(value)) {
    return Promise.reject('Field should contain only Latin characters or numbers.');
  }

  return true;
};

export const validateNameShort = async (_: unknown, value: string) => {
  const val = value?.trim();
  if (!val) return;
  // eslint-disable-next-line no-control-regex
  if (!/^[\x00-\x7F]+$/.test(value)) {
    return Promise.reject('Field should contain only Latin characters or numbers.');
  }

  if (val.length < 2) {
    return Promise.reject('Field should be at least 2 characters long.');
  }

  if (val.length > 30) {
    return Promise.reject('Field should be no longer than 30 characters.');
  }

  return true;
};

export const validateLatinCharacters = async (_: unknown, value: string) => {
  const val = value?.trim();
  if (!val) return;
  // eslint-disable-next-line no-control-regex
  if (!/^[\x00-\x7F]+$/.test(value)) {
    return Promise.reject('Field should contain only Latin characters or numbers.');
  }

  return true;
};

export const validateDescription = async (_: unknown, value: string) => {
  if (!value?.length) return true;
  if (value.length > 30000) {
    return Promise.reject('Field should be no longer than 30000 characters.');
  }

  return true;
};

export const validateUserPassword = async (_: unknown, value: string) => {
  if (!value?.trim()) return;

  if (value?.trim().length < 4) {
    return Promise.reject('Password should contain at least 4 characters.');
  }

  if (value?.trim().length > 24) {
    return Promise.reject('Password should be no longer than 24 characters.');
  }

  // if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!#$%&()*+,-./:;<=>?@[\]_`{|}~]).{6,24}$/.test(value)) {
  //   return Promise.reject('Password should contain at least 1 capital letter, 1 number, and 1 special character.');
  // }

  return true;
};

export const validateDiscount = (value: string) => {
  const trimmedValue = value.trim();
  const regex = /^\S\w+$/;
  if (!regex.test(trimmedValue) || trimmedValue.length < 2 || trimmedValue.length > 20) return Promise.reject();
  return Promise.resolve();
};

export const validateURL = async (_: unknown, value: string) => {
  if (!value) return;

  if (!/^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/.test(value)) {
    return Promise.reject('Enter valid url.');
  }

  return true;
};

export const requiredValidator = {
  validator: validateRequired,
};

export const nameValidator = {
  validator: validateName,
};

export const nameWithNumbersValidator = {
  validator: validateNameWithNumbers,
};

export const shortNameValidator = {
  validator: validateNameShort,
};

export const descriptionValidator = {
  validator: validateDescription,
};

export const emailValidator = {
  validator: validateEmail,
};

export const userPasswordValidator = {
  validator: validateUserPassword,
};

export const latinValidator = {
  validator: validateLatinCharacters,
};

export const urlValidator = {
  validator: validateURL,
};

export const externalCredentialsValidator = {
  validator: validateExternalCredentials,
};

export const stringLengthValidator = (min: number, max: number): Rule => ({
  validator: (_, value) => {
    const val = value?.trim();
    if (!val) return Promise.resolve();

    if (val.length < min) {
      return Promise.reject(`Field should be at least ${min} characters long.`);
    }

    if (val.length > max) {
      return Promise.reject(`Field should be no longer than ${max} characters.`);
    }

    return Promise.resolve();
  },
});

export const validateCheckboxGroupRequired = async (_: unknown, value: unknown) => {
  if (value === 0) return true;

  if (!value) {
    return Promise.reject('Please select at least one option');
  }

  if (typeof value === 'string' && !value.trim()) {
    return Promise.reject('Please select at least one option');
  }

  if (Array.isArray(value) && !value.length) {
    return Promise.reject('Please select at least one option');
  }

  return true;
};

export const checkboxGroupRequiredValidator = {
  validator: validateCheckboxGroupRequired,
};
